.header-mobile-section {
  padding: 12px 10px;

  &.header-border {
    border-bottom: 1px solid #e5e5e5;
  }

  .header-inner {
    display: flex;
    align-items: center;
  }

  .menu-mobile-btn {
    display: inline-block;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .header-logo {
    display: inline-block;
    width: 125px;
    min-width: 125px;
    margin: 0px 10px;
    position: relative;
    top: -3px;

    img {
      width: 100%;
      height: auto;
    }
  }

  //   .header-center {
  //     width: 100%;

  //     form {
  //       display: flex;
  //       justify-content: flex-end;
  //     }

  //     .header-search {
  //       position: relative;
  //       max-width: 100%;
  //       width: 100%;
  //       margin: 10px 6px 0px;
  //       border: 1px solid #ddd;

  //       .form-control {
  //         padding: 6px 40px 6px 15px;
  //         border-radius: 3px;
  //         box-shadow: none;
  //         height: 40px;
  //         width: 100%;
  //         font-size: 14px;
  //         line-height: 22px;
  //         font-weight: 400;
  //         color: #202020;
  //       }

  //       button {
  //         position: absolute;
  //         right: 7px;
  //         top: 10px;
  //       }
  //     }
  //   }

  .order-list {
    display: flex;
    align-items: center;
    margin-left: auto;

    li {
      display: inline-block;

      .order-link {
        display: inline-block;
        padding: 4px 8px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .order-card {
        position: relative;

        .card-number {
          padding: 1px;
          background: #f3601d;
          width: 13px;
          height: 13px;
          font-size: 9px;
          position: absolute;
          top: -4px;
          left: 22px;
          color: #fff;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.header-center {
  width: 100%;

  .header-search {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #e5e5e5;
    position: relative;

    .form-control {
      font-size: 0.9375rem;
      width: 100%;
      appearance: none;
      padding: 0 10px 0 0px;
      font-size: 0.9375rem;
    }

    .search-form {
      position: relative;
      font-size: 14px;
      width: 100%;
      display: flex;
    }

    .search svg,
    .search img {
      vertical-align: middle;
      margin-right: 10px;
    }

    .search:active {
      right: -100%;
    }

    .close-svg {
      padding: 3px;
    }

    .close-svg svg {
      vertical-align: middle;
    }

    .header-search #search-form {
      width: 100%;
      display: flex;
    }

    .voice-search {
      padding: 0;
    }

    .voice-search svg {
      vertical-align: middle;
    }
  }
}

.resent-searchs {
  height: 100%;
  overflow: scroll;
  background: #f5f5f5;

  .search-menu {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background: #fff;

    li {
      position: relative;
      text-transform: capitalize;

      a,
      span {
        padding: 15px 0 15px 40px;
        position: relative;
        display: block;
        color: #8b8b8b;
        font-size: 0.875rem;
      }

      span:before,
      a:before {
        content: '';
        position: absolute;
        left: 11px;
        top: 16px;
        width: 24px;
        height: 24px;
        background-size: 20px;
        background: url('/images/header/resent.svg') no-repeat;
      }

      span:before {
        background: url('/images/header/searchicon.svg') no-repeat;
      }

      span b,
      a b {
        font-family: 'robotomedium', Arial, Helvetica, sans-serif;
        font-weight: normal;
        color: #000;
      }

      &:hover {

        span b,
        span.a,
        a b {
          color: #ff7204;
        }
      }
    }
  }

  .Trending {
    padding: 20px 10px;
    background: #fff;
    margin: 10px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    .cat-head {
      display: block;
      color: #131313;
      margin-bottom: 10px;
      font-size: 1.0625rem;
      line-height: 20px;
      font-family: 'pangrammedium';
    }

    .cat-list {
      border-bottom: 1px solid #f5f5f5;
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      margin-right: -10px;
    }

    .cat-list::-webkit-scrollbar {
      display: none;
    }

    .cat-list .category-title {
      color: #646464;
      padding: 8px 10px 8px 0px;
      font-size: 0.875rem;
      line-height: 16px;
      border-bottom: 2px solid transparent;
      display: inline-block;
      margin-right: 10px;
    }

    .cat-list .category-title.active {
      color: #E27A34;
      border-bottom: 2px solid #E27A34;
    }

    .ws-scroll {
      margin-top: 20px;
      display: block;
      overflow: auto;
      white-space: nowrap;
    }

    .ws-scroll .category-list {
      display: flex;
      flex-wrap: wrap;

      &.hide {
        display: none;
      }
    }

    .ws-scroll .single-category {
      white-space: normal;
      width: 31.4%;
      text-align: center;
      font-size: 0.75rem;
      line-height: 15px;
      font-family: 'pangramregular';
      letter-spacing: 0.01em;
      color: #646464;
      margin: 0 3px 10px;
    }

    .ws-scroll .single-category figure {
      margin-bottom: 5px;
    }

    .ws-scroll .single-category figure img {
      position: relative;
      border-radius: 3px;
    }
  }
}

.search-m {
  padding: 2px 10px 10px 10px;
  background: #fff;
  margin-top: -2px;
  position: relative;
  display: block;
  width: 100%;

  .fullsrch {
    width: 100%;
    font-family: 'pangramregular';
    display: flex;
    padding: 10px 12px;
    border: 1px solid #acacac;
    justify-content: space-between;
    align-items: center;
    color: #aaa7a7;
    border-radius: 2px;
  }

  .fullsrch i {
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  .fullsrch i svg {
    width: 22px;
    height: 22px;
  }
}

// menu-header
.menu-header {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .menu-header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px;
    border-bottom: 1px solid #e7e7e7;
  }

  .closemenu {
    padding: 0px 16px 0px 0px;
    display: inline-block;
    position: relative;
    top: 4px;
    cursor: pointer;
  }

  .mobile-logo {
    display: inline-block;
    margin-right: auto;

    img {
      max-width: 118px;
    }
  }

  .header-login {
    .login-link {
      color: #131313;
      font-size: 14px;
      line-height: 19px;
      font-family: 'pangramregular';
      display: inline-block;
      padding: 2px;
    }
  }

  .store-menu {
    padding: 6px 20px;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    position: relative;
    border-bottom: 1px solid #f5f5f5;

    &:after {
      background-image: url(/images/client/CaretRight.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 21px;
      height: 19px;
      position: absolute;
      content: '';
      right: 20px;
    }

    img {
      width: 45px;
      height: auto;
      margin-right: 14px;
    }

    .menu-link {
      display: block;
      font-size: 13px;
      line-height: 16px;
      font-family: 'pangramregular';
      color: #333333;
      letter-spacing: 0.01em;
      font-weight: 400;
    }

    p {
      color: #E27A34;
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      font-family: pangrammedium;
    }
  }

  .menu-wrapper {
    ul {
      li {
        display: block;
      }
    }

    .outer-menu {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      background: #fff;
      position: relative;
      border-bottom: 1px solid #f5f5f5;

      &:after {
        background-image: url(/images/client/CaretRight.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 21px;
        height: 19px;
        position: absolute;
        content: '';
        right: 20px;
      }

      img {
        width: 45px;
        height: auto;
        margin-right: 14px;
      }

      .menu-link {
        display: block;
        font-size: 14px;
        line-height: 16px;
        font-family: 'pangramregular';
        color: #333333;
        letter-spacing: 0.01em;
        font-weight: 400;
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  .menu-footer {
    padding: 15px 0 130px;
    background-color: #f5f5f5;

    ul {
      li {
        display: block;
      }
    }

    .outer-menu {
      padding: 9px 15px 9px 27px;
      display: flex;
      align-items: center;
      background: transparent;
      position: relative;

      img {
        width: 22px;
        height: auto;
        margin-right: 15px;
      }

      .menu-link {
        display: block;
        font-size: 13px;
        line-height: 16px;
        font-family: 'pangramregular';
        color: #515151;
        font-weight: 400;
      }
    }
  }
}

.submenu-box {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: auto;
  background-color: #ffffff;
  transition: all 0.8s ease;

  // display: none;
  .submenu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    border-bottom: 1px solid #e7e7e7;

    .close-submenu {
      padding: 0px 16px 0px 0px;
      position: relative;
      top: 2px;
    }

    .submenu-name {
      font-size: 17px;
      line-height: 20px;
      color: #333333;
      font-family: 'pangrammedium';
      margin-right: auto;
    }

    .header-all-view {
      .all-view-link {
        font-size: 15px;
        line-height: 18px;
        font-family: 'pangrammedium';
        color: #E27A34;
        letter-spacing: 0.03em;
      }
    }
  }

  .shop-category {
    padding: 14px 0px 10px;
    display: block;

    .top-pickshe-card {
      display: inline-block;
      margin-bottom: 10px;
      text-align: center;
      width: 100%;
      // padding: 0px 5px;

      .top-picks-img {
        margin: 0px auto 4px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .top-picks-text {
        .category-name {
          font-size: 12px;
          line-height: 16px;
          color: #333333;
          font-family: 'pangramregular';
          text-transform: capitalize;
          letter-spacing: 0.01em;
          margin: 0px;
          transition: 0.3s all ease-in-out;
        }
      }

      &:hover {
        .top-picks-text {
          .category-name {
            color: #E27A34;
          }
        }
      }
    }
  }

  .product-links {
    .subinner-menu {
      .product-sub-links {
        display: block;
        font-size: 14px;
        line-height: 40px;
        font-family: 'pangramregular';
        color: #333333;
        padding: 0px 27px;
      }
    }
  }
}

.heading {
  font-size: 0.9375rem;
  line-height: 16px;
  font-family: 'pangrammedium';
  color: #333333;
  padding: 20px 16px;
}

.product-links {
  text-transform: capitalize;
}

.product-link-name {
  button {
    font-family: 'pangrammedium' !important;
  }
}

.submenu-wrapper {
  border-bottom: 1px solid #e5e5e5;
  margin: 0 0 15px;
}

@media screen and (max-width:320px) {
  .header-mobile-section {
    .header-logo {
      min-width: 100px;
      margin: 0 6px;
    }
  }
}